import { Box, Typography} from "@mui/material"
import ChartLayout from "layout/dashboard/main/chart-layout";
import DashboardTable from "layout/dashboard/main/table-layout";
import BottomTab from "component/BottomTab";
import { useEffect, useMemo, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { getPortfolio,mycoin,requestUpdate } from "api/crypto";
import CryptoTable from 'layout/dashboard/category/category-table-layout'
import { set_crypto, setLive } from "redux/reducer/crypto";
import FourYearCycle from "component/FourYearCycle";
import addEvent from "utils/addEvent";
import Greeting from "component/Modal/Greeting";
import PortfolioTracker from "component/PortfolioTracker";
import WelcomeModal from "component/Modal/WelcomeModal";
export const MainDashboard = () => {
    const {crypto,live} = useSelector(state=>state.crypto)
    const dispatch=useDispatch();
    const [coins,setCoins]=useState([]);
    const [showNotification,setShowNotification]=useState(false);
    const [showWelcome,setShowWelcome]=useState(false);
    

    useEffect(()=>{
        let key='lastDate-AvgBuy';
        let date=new Date().toLocaleDateString();
        let lastDate=localStorage.getItem(key);
        if(lastDate){
            if(date==lastDate){
                return
            }
        }
        localStorage.setItem(key,date)
        setShowNotification(true);
       addEvent('Pro Dashobard')
    },[])
    const fetchData=()=>{
        getPortfolio('main')
        .then(res=>{
           dispatch(set_crypto(res.data.data[0].assets))
        }).catch((err)=>{
            if(err.response.data=='please add coins'){
                let today=new Date().toLocaleDateString();
                if( localStorage.getItem('user-welcome')!=today){
                    localStorage.setItem('user-welcome',today);
                    setShowWelcome(true);
                }

            }
        })
    }
    useEffect(()=>{
        updateTask();
       let timer=setInterval(()=>{
         updateTask();
       },20000);
       return ()=>clearInterval(timer)
    },[])

    useEffect(()=>{
      requestUpdate();
      let timer=setInterval(()=>{
        requestUpdate();
      },60*1000*3);
      return ()=>clearInterval(timer) 
    },[])

    const updateTask=async()=>{
        fetchData();
        fetchMyCoins();
    }
    const fetchMyCoins=()=>{
        mycoin()
        .then((res)=>{
            setCoins(res.data?.data[0]?.assets)
            dispatch(setLive(res.data.updatedPrice))
        })
        .catch(err=>console.log(err))
    }

    // const belowAvgBuyCoinsCount=useMemo(()=>{
    //     if(crypto.length){
    //         let arr=crypto.filter(obj=>obj.price<=obj.avg_buy);
    //         return arr.length;
    //     }
    // },[crypto])

    const greetData=useMemo(()=>{
        if(crypto.length){
            let arr=crypto.filter(obj=>obj.price<=obj.avg_buy);
            let total_p_and_l= crypto.map(obj =>{
                if((obj.price*obj.qty)>=obj.invested ){
                    return (obj.price*obj.qty)-obj.invested
                }
                return 0;
            }).reduce((a, b) => a + b, 0)
            return {belowAvg:arr.length, p_and_l:total_p_and_l};
        }
        return null
    },[crypto])
  
    return (
        <Box sx={{overflow:'auto'}}>
           {/* {(showNotification&& belowAvgBuyCoinsCount) &&  <SnackbarSucess text={`You have ${belowAvgBuyCoinsCount} coins below your Average Buy price`} showOneTimeinDay={false}/>}
           */}

            {(showNotification && greetData) && <Greeting belowAvg={greetData.belowAvg} totalCoin={crypto?.length} setShow={setShowNotification} p_and_l={greetData.p_and_l}  />} 
            {showWelcome && <WelcomeModal handleClose={setShowWelcome} />} 
            <ChartLayout p_and_l={greetData?.p_and_l||0} crypto={crypto}/>
            <PortfolioTracker/>
            <FourYearCycle/>
            <DashboardTable crypto={crypto}/>
            <CryptoTable data={coins||[]} refresh={()=>{fetchMyCoins();fetchData();}}/>
            <Box sx={{marginTop:10}}></Box>
            <BottomTab/>
        </Box>
    )
}
export default MainDashboard;