import { Button, Typography, Box } from "@mui/material";
import RedOutlineButton from "../../components/RedOutlineButton";
import Section from "../../components/Section";
import BigX from 'assets/bigx.png'
import LogoImg from 'assets/xgainTM.png'
import CheckCircleOutlineIcon from '@mui/icons-material/DoneAll';

const FeatureItem = ({text}) => (
  <Box sx={{display:'flex',alignItems:'center',mt:1}}>
    <CheckCircleOutlineIcon sx={{ marginRight: '8px', color: '#fff'}} />
    <Typography sx={{ color: '#fff', }}>{text}</Typography>
  </Box>
)


const HeroSection = () => {
  return (<Section maxHeight={{xs:"max-content",md:"max-content"}} sx={{pb:5, bgcolor: 'black',height:'max-content', borderBottom: '1px solid #fff', paddingtTop: 10 ,display:{xs:'flex',md:'block'},flexDirection:'column'}}>
    <Box component={'img'}
      sx={{
        mx:'auto',
        height:{xs:"50vh",md:"100vh"},
        marginTop:{xs:"-10vh",md:'-30vh'},
        display:'block'
      }}
      src={BigX}
      alt="Large letter x in red design" />
    <Box sx={{
      px: { xs: 4, md: 0 },
      marginTop:{xs:"-10vh",md:"-20vh"},
      zIndex: 1,
      position: 'relative',
      maxWidth: {
        md: '50%',
        lg: '40%'
      },
      marginLeft: { md: 20 },
      // marginTop: {
      //   sm: '10%',
      //   md:'3%'
      // }
    }}>
      
      <Box component={'img'} sx={{height:{xs:30,md:40,lg:50},my:2,mx:{xs:'auto',md:2},display:{xs:'block',md:'flex',mb:4}}} src={LogoImg} alt='logo' />
      <Typography sx={{ color: '#fff', fontSize: 25, fontWeight: 'bold',textAlign:{xs:'center',md:"left"} }}>The Future of Smarter Crypto Investing</Typography>
      <Typography sx={{ color: '#fff', fontSize: 16,  }}>Macro Crypto investors' vital information provided with intricate calculations, automated fibonacci charts, crucial alerts combined with key call to actions for Planing, Estimating, Tracking crypto assets, organized under categories, enabled with Exit Strategies 
      adjusted on Fibonacci charts.</Typography>
      
      <Typography sx={{marginTop:2, }}>
       
          <FeatureItem text={<>Portfolio Dashboard for Total<span style={{ color: 'red',fontsize: '16px', fontWeight: 'bold' }}> X</span>Gain</>}/>
          <FeatureItem text={<>Category Dashboards showing <span style={{ color: 'red', fontWeight: 'bold' }}>X</span>Gains</>}/>
          <FeatureItem text={<>Crypto Dashboards showing <span style={{ color: 'red', fontWeight: 'bold' }}>X</span>Gains</>}/>
          <FeatureItem text={'Crypto performance breakdown'}/>
          <FeatureItem text={'Auto Fibonacci Charts'}/>
          <FeatureItem text={'Exit Strategies on Fibonacci Charts'}/>
          <FeatureItem text={'BTC 4 Year Cycle Gauge'}/>
          <FeatureItem text={'Links to Exchanges & Wallets'}/>
          <FeatureItem text={<>Price Discovery level<span style={{ color: 'red', fontWeight: 'bold' }}> X</span>Gains</>}/>
          
      </Typography>
      {/* <RedOutlineButton sx={{ marginTop: 3 }} /><br /><br /> */}

      {/* <Typography  sx={{color:'#fff',fontSize:'1.2rem',fontWeight:'bold'}}>Download 7 Lethal Traps Crypto Investors Falling To!</Typography> */}
    </Box>
  </Section>)
}

export default HeroSection;