import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CustomInput from 'component/Input/CustomInputDark';
import { addCrypto, addMoreCrypto, getCryptoById, sellCrypto } from 'api/crypto';
import { useDispatch } from 'react-redux';
import { set_message } from 'redux/reducer/message';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 410,
    width:'90%',
    margin:'auto',
    bgcolor: 'primary.light',
    border: '1px solid #000',
    boxShadow: 24,
    color: 'common.white',
    p: 1.8,
    borderRadius: 3,
    marginBottom: 50,
};





export default function SellCrypto({refresh, visible, setVisble,id,symbol }) {
    const [open, setOpen] = React.useState(visible);
    const [data, setData] = React.useState({qty:null});
    const [qty, setQty] = React.useState(0);
    const [sell, setSell] = React.useState(0);
    const [key,setkey]=React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const dispatch=useDispatch();
    React.useEffect(()=>{
        getCryptoById(id)
        .then(res=>setData(res.data))
        .catch(err=>console.log(err))
    },[])
    React.useEffect(() => {
        if(visible){
            setkey(Math.random())
        }
        setOpen(visible);
    }, [visible]);

    const handleClose = () => {
        setOpen(false);
        setVisble(null);
    }

    const handleChange = (e) => {
        setQty(e.target.value);
    }

    const handlePriceChange = (e) => {
        setSell(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(loading)return;
        data.symbol=symbol;
        if(qty>data.qty)return alert('quantity exceed');
        setLoading(true);
        sellCrypto({id,qty:qty,price:sell})
        .then(res=>{
            dispatch(set_message(`updated info for ${symbol}`))
            refresh();
            handleClose();
        })
        .catch(err=>console.log(err))
        .finally(()=>setLoading(false))
    }
       
    return (
        <Box 
        key={key}
        >
        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box component='form' onSubmit={handleSubmit} sx={style}>
                <Box sx={{ marginBottom: 4, marginTop: 2, textAlign: 'center' }}>
                <img src={`https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/64/${symbol.toLowerCase()}.png`}/><br/><br/>
                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        Sell {symbol?.replaceAll('_','')}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant='body2' sx={{ color: '#fff', textAlign: 'center' }}>
                        Total Quantity : <Button variant="text" sx={{color:"#fff"}} onClick={()=>setQty(data.qty)}>{data.qty}</Button>
                    </Typography>
                </Box>
                <Box>
                     <CustomInput  onChange={handleChange} type='text' inputMode="numeric" max={parseFloat(data.qty)} value={qty}  name='qty' sx={{ mt: 2 }} label={`Quantity (max : ${data.qty})`} />
                     <CustomInput  onChange={handlePriceChange} type='text' inputMode="numeric" value={sell}  name='price' sx={{ mt: 2 }} label={`Selling Price`} />
                </Box>
                <Box sx={{ width: '100%', height: 1.1, background: '#fff', marginTop: 3 }} />
                <Box sx={{ paddingTop: 3,ml:'auto',display:'block',width:'max-content'}}>
                    <Button onClick={handleClose} sx={{ color: '#fff',  borderRadius: '20px', mr: 2,textTransform:'capitalize'}} variant='outlined'>Cancel</Button>
                    <Button  type='submit' sx={{ color: '#fff', borderColor: "#fff", borderRadius: '20px',textTransform:'capitalize' }} variant="outlined">
                      {loading?"Updating...":"Save"}
                    </Button>
                </Box>
            </Box>
        </Modal>
        </Box>
    );
}


