import { CheckCircle } from "@mui/icons-material"
import { Typography,Box } from "@mui/material";
import { lightBlue } from "@mui/material/colors";
import { buyPlanApi } from "api/payment";
import Logo from 'assets/logo.svg'
import { useDispatch,useSelector } from "react-redux";
import { set_message } from "redux/reducer/message";
const PlanButton=({active=true,name,pricing,id})=>{
    const { plan } = useSelector(state => state.user);
    const dispatch=useDispatch();
    const buyPlan=()=>{
        if( active){
            return dispatch(set_message('Plan Already Purchased!'))
        }
        if(plan.name=='Pro'){
            return dispatch(set_message(`Downgrade is not possible!`))
        }
        buyPlanApi(id)
        .then(res=>{
            window.location.href=res.data.url;
        })
        .catch(()=>{})
    }
    return(
    <Box component={'button'} sx={{cursor:'pointer',width:'45%',border:active?'2px solid grey':'none',
    padding:'15px',
    position:'relative',
    borderRadius:5,
    backgroundColor:'#293143',
    "&:hover":{
        bgcolor:'primary.light'
    }
    }}
    onClick={buyPlan}
    >
        <Box sx={{display:'flex',justifyContent:"center"}}>
            <img style={{height:60}} src={Logo}/>
            {active && <CheckCircle sx={{color:'#fff', position:'absolute',top:12,right:12}}/>}
        </Box>
        <Typography sx={{textAlign:'center', color:'#fff'}} variant="h6"> {name}</Typography>
        <Typography sx={{textAlign:'center', color:'#fff',fontSize:24,fontWeight:'bold'}}>{pricing}</Typography>
        <Typography sx={{textAlign:'center', color:'#fff',fontSize:12,fontWeight:'bold'}}>PAID PLAN</Typography> 
        <Typography sx={{textAlign:'center', color:'#fff',fontSize:12,fontWeight:'bold'}}>TAX DEDUCTIBLE</Typography>
        <Typography sx={{textAlign:'center', color:'#fff',fontSize:14,fontWeight:'bold'}}>Billed Annually</Typography>
       
    </Box>
    )

}

export default PlanButton; 