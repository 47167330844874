import * as React from "react";
import { Box, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import logo from "assets/logo.svg";
import CustomInput from "component/Input/CustomInput";
import PhoneNumberInput from "component/Input/PhoneInput";
import CustomButton from "component/Buttons/CustomButton";
import PrivacyPolicyText from "component/PrivacyPolicyText";
import { register } from 'api';
import {set_user} from 'redux/reducer/user'
import { useDispatch } from "react-redux";
import { set_message } from "redux/reducer/message";
import { useFormik } from 'formik';
import * as yup from 'yup';
import ReCAPTCHA from "react-google-recaptcha";
import Section from "../home/components/Section";

const validationSchema = yup.object({
  first_name: yup.string().required('Required').max(20,'Max 20 Characters').matches(/^[^0-9]*$/, 'Numbers not allowed'),
  last_name: yup.string().required('Required').max(20,'Max 20 Characters').matches(/^[^0-9]*$/, 'Numbers not allowed'),
  email:  yup.string().required('Required').email('invalid email').max(50,'maximum length is 50'),
  phone:  yup.string().required('Required').max(10,'Max 10 Digits').matches(/^[0-9]+$/, 'Numbers only'),
});


export const Register = () => {

  React.useEffect(()=>{
    document.title='XGain | Register'
  },[])
  const [data, setData] = React.useState({
    first_name: '',
    last_name: '',
    email: '',
    country_code: '+1',
    phone: '',
    password: '',
    confirm_password: '',

  });
  const [captcha,setCaptcha]=React.useState(null);
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      country_code: '+1',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
    if(values.email.includes('yopmail')){
      return  formik.setFieldError('email','Invalid Email')
    }
    register({...values,captcha})
    .then(res=>{
      dispatch(set_user(res.data));
      navigate('/verify/phone');
    })
    .catch(err=>{
      if(err.response.data.phone){
        formik.setFieldError('phone',err.response.data.phone)
      }
      if(err.response.data.email){
        formik.setFieldError('email',err.response.data.email)
      }
    })

    },
  });


  const navigate=useNavigate();
  const dispatch=useDispatch();
  const handleChange = (event) => {
    setData(prev => ({ ...prev, [event.target.name]: event.target.value }))
  }

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, ''); // Remove all digits (numbers)
    formik.setFieldValue(e.target.name, sanitizedValue);
  };



  return (
    <Section sx={{marginBottom:"10%"}}>
    <Box
      component="form"
      sx={{
        maxWidth: 400,
        width: "92%",
       
      }}
      mx="auto"
      method="post"
      onSubmit={formik.handleSubmit}
    >
      <Box
        display="flex"
        sx={{ height: "15vh", justifyContent: "center", alignItems: "center" }}
      >
        <img src={logo} height="80px" alt="logo" />
      </Box>
      <Typography mt={3} variant="h6">
        Sign Up to join Now
      </Typography>
      <Box mt={2}>
        <CustomInput  
           name='first_name' 
           label="First Name" 
           value={formik.values.first_name} 
           onChange={handleInputChange}  
           error={formik.touched.first_name && Boolean(formik.errors.first_name)}  
           onBlur={formik.handleBlur}
           helperText={formik.touched.first_name && formik.errors.first_name}
        />
        <CustomInput 
            name='last_name' 
            label="Last Name" 
            value={formik.values.last_name} 
            onChange={handleInputChange}  
            error={formik.touched.last_name && Boolean(formik.errors.last_name)}  
            onBlur={formik.handleBlur}
            helperText={formik.touched.last_name && formik.errors.last_name}
            />
        <CustomInput 
             name='email' 
             label="Email"
             onChange={formik.handleChange}
             onBlur={formik.handleBlur}
             error={formik.touched.email && Boolean(formik.errors.email)}
             helperText={formik.touched.email && formik.errors.email}
             />
        <PhoneNumberInput 
          name={'phone'}
          label="Phone"
          data={formik.values}
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur} 
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
          formik={formik}
          />
      </Box>
      <Box sx={{mx:'auto',display:'flex',justifyContent:'center',my:2}}>
      <ReCAPTCHA
       sitekey="6Ld8rWknAAAAAD_jifcFUozxmQK79tSd4J4V9C2p"
       onChange={setCaptcha}
     />
      </Box>


     {captcha&& <CustomButton type='submit'  text="" />}
      <Box my={0} textAlign="center">
        <Typography variant="p" color="grey">
          Already joined?
          <Link to="/login" style={{ marginLeft:4,color:'#1976d2',textDecoration:'none' }} variant="primary">
            Login
          </Link>
        </Typography>
      </Box>
      <PrivacyPolicyText />
    </Box>
    </Section>
  );
};

export default Register;
