import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Logo from 'assets/logo.svg'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LogoText from 'component/Logo';
import LogoImg from 'assets/xgainTM.png'
import { NavHashLink } from 'react-router-hash-link';

const drawerWidth = 240;
const navItems = [{ text: 'Merch', link: 'https://xgain-store.creator-spring.com/',target:'_blank' },

// { text: 'Pricing', link: '/#pricing' }, 


{ text: 'Join Now', link: '/register' },
{ text: 'Login', link: '/login' },

];

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        XGAIN
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          
          <ListItem key={item} disablePadding>
             <Link style={{width:'100%', textDecoration:'none',color:'#000',textAlign:'center'}} target={item.target} to={item.link}>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item.text} />
            </ListItemButton>
            </Link>
          </ListItem>
         
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;



  return (
    <Box sx={{ display: 'flex', marginBottom: 7 }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <Box sx={{ padding: '1px 5px', display: 'flex', alignItems: 'center', justifyContent: { xs: 'space-between' }, width: { xs: '100%', md: 'auto' } }}>
            <Link  style={{display:'flex',textDecoration:'none',color:'#fff'}} to='/'>
              <Typography
                variant="h6"
                component="div"
                sx={{ alignItems: 'center', display: { xs: 'flex' } }}
              >
                <img height={50} src={Logo} alt='logo' />
              </Typography>
              <Typography  sx={{ fontSize:{xs:26,sm:30}, fontWeight: 'bold', textAlign: 'center',display:'flex',alignItems:'center'}}>
                <Typography component={'span'} sx={{fontSize:'inherit',fontWeight:'bold',display:{xs:"none",md:'inline'}}}>Crypto</Typography>
                 <Box component='img' marginLeft={1} height={{xs:25,md:30,lg:30}} src={LogoImg}/>
              </Typography>
            </Link>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Box sx={{ ml: 'auto', width: 'max-content', display: { xs: 'none', sm: 'flex' } }}>
            {navItems.map((item) => (
              <NavHashLink to={item.link} target={item.target}>
                <Button key={item.text} sx={{ color: '#fff' }}>
                  {item.text}
                </Button>
              </NavHashLink>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"

          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
