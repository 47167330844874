import { Table, TableBody, TableCell, TableHead, TableRow, Typography,Box,Button, FormGroup, FormControlLabel,Checkbox } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormateCurrency from "utils/FormateCurrency";
import FormateText from "utils/FormateText";
import addEvent from "utils/addEvent";

const Text=({children,sx})=>(
    <Typography sx={{display:'inline',fontWeight:'bold',ml:1,...sx}}>
        {children}
    </Typography>
)


const CommonDetail=({data})=>{
    let labels=[
        <Text sx={{fontWeight:'100'}}>Symbol</Text>,
        <Text sx={{fontWeight:'100'}}>Qty</Text>,
        <Text sx={{fontWeight:'100'}}>Avg Buy</Text>,
        <Text sx={{fontWeight:'100'}}>ATH</Text>,
        <Text sx={{fontWeight:'100'}}>ATL</Text>,
        <Text sx={{fontWeight:'100'}}>Category</Text>
    ]
    let values=[
        <Text>{data.acitivity_data?.symbol.replaceAll('_','')}</Text>,
        <Text>{data.acitivity_data?.qty}</Text>,
        <Text>{FormateCurrency(parseFloat(data?.acitivity_data?.avg_buy))}</Text>,
        <Text>{FormateCurrency(parseFloat(data.acitivity_data?.ATH))}</Text>,
        <Text>{FormateCurrency(parseFloat(data.acitivity_data?.ATL))}</Text>,
        <Text>{data.acitivity_data?.category}</Text>,
    ]
    return values.map((_,index)=><>{labels[index]} {values[index]} </>)
}

const formateTable=(heading=[],row1,row2)=>{
    return(
        <table>
            <tbody>
                <tr>
                    {row2 && <td><Text>New</Text></td>}
                    {heading.map(text=>(
                        <td> 
                            <Text sx={{fontWeight:'100'}}>{text}</Text>,
                            <Text>{row1[text]}</Text>
                        </td>))}
                    {row1.Price && (
                    <td> 
                        <Text sx={{fontWeight:'100'}}>Price</Text>,
                        <Text>{row1['Price']}</Text>
                    </td>
                    )}
                </tr>
                
                {row2 && (
                    <tr>
                    <td><Text>Old</Text></td>
                    {heading.map(text=>(
                            <td> 
                                <Text sx={{fontWeight:'100'}}>{text}</Text>,
                                <Text>{row2[text]}</Text>
                            </td>))}
                    </tr>
                )}
            </tbody>
        </table>
    )
}
const getDetail=(data)=>{
    switch(data.activity_type){
        case "ADD MORE COIN":{
        let labels=[
            <Text sx={{fontWeight:'100'}}>Symbol</Text>,
            <Text sx={{fontWeight:'100'}}>Added Qty</Text>,
            <Text sx={{fontWeight:'100'}}>OLD Qty</Text>,
            <Text sx={{fontWeight:'100'}}>New Ave Buy</Text>,
            <Text sx={{fontWeight:'100'}}>Old Ave Buy</Text>,
        ]
        let values=[
            <Text>{data.acitivity_data.symbol.replaceAll('_','')}</Text>,
            <Text>{data.acitivity_data.qty}</Text>,
            <Text>{data.acitivity_data.old_qty||"NA"}</Text> ,
            <Text>{FormateCurrency(parseFloat(data.acitivity_data.new_avg_buy||0))}</Text>,
            <Text>{FormateCurrency(parseFloat(data?.acitivity_data?.avg_buy||0))}</Text> 

        ]
        let heading=['Symbol','Qty', 'Avg Buy']
        let row1={
            Symbol:data.acitivity_data.symbol.replaceAll('_',''),
            Qty:data.acitivity_data.qty,
            'Avg Buy':FormateCurrency(parseFloat(data.acitivity_data.new_avg_buy||0)),
            'Price':FormateCurrency(parseFloat(data.acitivity_data.price||0))
        }
        let row2={
            Symbol:data.acitivity_data.symbol.replaceAll('_',''),
            Qty:data.acitivity_data.old_qty,
            'Avg Buy':FormateCurrency(parseFloat(data?.acitivity_data?.avg_buy||0))
        }
        return formateTable(heading,row1,row2)
        

    }
        case "SELL_CRYPTO":
            let heading=[
                "Symbol",
                "Qty",
                "Selling Price",
            ]
            let rows={
                "Symbol":data.acitivity_data?.symbol?.replaceAll('_',''),
                "Qty":data.acitivity_data?.qty,
                "Selling Price":FormateCurrency(parseFloat(data.acitivity_data?.selling_price))
            }
            return formateTable(heading,rows)

        case "ADD NEW COIN":
            return <CommonDetail data={data}/>
        
        case "DELETE_COIN":{
            return <CommonDetail data={data}/>
        }
        case "EDIT COIN":{
            let heading=['Symbol','Qty', 'Avg Buy','ATH','ATL','Category']
            let row1={
             'Symbol':data.acitivity_data?.symbol.replaceAll('_',''),
             'Qty':data.acitivity_data?.qty,
             'Avg Buy':FormateCurrency(parseFloat(data?.acitivity_data?.avg_buy)),
             'ATH':FormateCurrency(parseFloat(data.acitivity_data?.ATH)),
             'ATL':FormateCurrency(parseFloat(data.acitivity_data?.ATL)),
             'Category':data.acitivity_data?.category
            }
            let row2={
             'Symbol':data.acitivity_data?.symbol.replaceAll('_',''),
             'Qty':data.acitivity_data?.old_qty,
             'Avg Buy':FormateCurrency(parseFloat(data?.acitivity_data?.old_avg_buy)),
             'ATH':FormateCurrency(parseFloat(data.acitivity_data?.old_ATH)),
             'ATL':FormateCurrency(parseFloat(data.acitivity_data?.old_ATL)),
             'Category':data.acitivity_data?.old_category
            }
            return formateTable(heading,row1,row2)
        }

        case "EXPORT_EXCEL":
            return null

        case "UPDATED_SELL":
            let labels=[
                <Text sx={{fontWeight:'100'}}>Symbol</Text>,
                <Text sx={{fontWeight:'100'}}>Xgain</Text>,
                <Text sx={{fontWeight:'100'}}>Grand Total Sell</Text>,
                <Text sx={{fontWeight:'100'}}>Total Profit</Text>,
            ]
            let values=[
                <Text>{data.acitivity_data.symbol.replaceAll('_','')}</Text> ,
                <Text>{data.acitivity_data.xgain}</Text>,
                <Text>{FormateCurrency(parseFloat(data.acitivity_data.grand_total_sell||0))}</Text>,
                <Text>{FormateCurrency(parseFloat(data?.acitivity_data?.total_profit_sell||0))}</Text>,    
            ]
            // let new_row=values.map((_,index)=><>{labels[index]} {values[index]} </>)
            // let old_row=values.map((_,index)=><>{labels[index]} {values[index]} </>)
        
            
        default:
            return <Text>No Detai's</Text> 

    }
}


export const Activity = () => {
    const [transaction,setTransaction]=useState([]);
    const [showActivities,setShowActivities]=useState(['ADD NEW COIN','ADD MORE COIN'])
    const navigateTo=useNavigate();
    useEffect(() => {
        fetch('/api/v1/user/activity')
        .then(res=>res.json())
        .then(res=>setTransaction(res))

        addEvent("View Activity Page")
        
     }, [])

     const handleCheck=(event)=>{
        
        if(event.target.checked){
            showActivities.push(event.target.name)
            setShowActivities([...showActivities])
        }else{
            let index=showActivities.indexOf(event.target.name);
            if(index!=-1){
                showActivities.splice(index,1);
                setShowActivities([...showActivities])
            }
        }
     }

     const goToHome=()=>{
        navigateTo('/dashboard/main')
     }

     const getRows=useMemo(()=>{
       return transaction.filter(element=>showActivities.includes(element.activity_type)).map((element,index)=>
            (
                <TableRow key={element._id} style={{borderColor:'none'}} >
                    <TableCell sx={{color:"#fff"}}>{index+1}</TableCell>
                    <TableCell sx={{color:"#fff"}}>{new Date(element.createdAt).toLocaleString()}</TableCell>
                    <TableCell sx={{color:"#fff"}}> {FormateText(element.activity_type)} </TableCell>
                    <TableCell sx={{color:"#fff"}}>
                        {getDetail(element)}
                    </TableCell>
                </TableRow>
            )
          )
     },[showActivities,transaction])
  
    return (
        <div>
            <Box sx={{display:'flex',justifyContent:'space-between',mt:2}}>
            <Typography variant="h5" color="#fff" gutterBottom>ACTIVITY LOG</Typography>
            <Box sx={{display:{xs:'none',lg:'flex'}}}>
                <FormGroup sx={{display:'flex',flexDirection:'row',gap:4,color:'#fff'}} >
                    <FormControlLabel control={<Checkbox style={{color:'#fff'}} checked={showActivities.includes('ADD NEW COIN')} onChange={handleCheck}  name="ADD NEW COIN"/>} label='Add New'/>
                    <FormControlLabel control={<Checkbox style={{color:'#fff'}} checked={showActivities.includes('ADD MORE COIN')} name="ADD MORE COIN" onChange={handleCheck}/>}   label='Add More'/>
                    <FormControlLabel control={<Checkbox style={{color:'#fff'}} checked={showActivities.includes('DELETE_COIN')} onChange={handleCheck}  name="DELETE_COIN"/>} label='Deleted'/>
                    <FormControlLabel control={<Checkbox style={{color:'#fff'}} checked={showActivities.includes('EDIT COIN')} onChange={handleCheck}  name="EDIT COIN"/>} label='Updated'/>
                    <FormControlLabel control={<Checkbox style={{color:'#fff'}} checked={showActivities.includes('LOGIN')} onChange={handleCheck}  name="LOGIN"/>} label='Log Ins'/>
                    <FormControlLabel control={<Checkbox style={{color:'#fff'}} checked={showActivities.includes('UPDATED_SELL')} onChange={handleCheck}  name="UPDATED_SELL" />} label='Xgain Updates'/>
                    <FormControlLabel control={<Checkbox style={{color:'#fff'}} checked={showActivities.includes('SELL_CRYPTO')} onChange={handleCheck}  name="SELL_CRYPTO" />} label='SELL_CRYPTO'/>
                </FormGroup>
            </Box>
            <Button onClick={goToHome} sx={{ color: '#fff', px: 4, borderColor: "#fff", borderRadius: '20px' }} variant="outlined">Home</Button>
            </Box>
            <Box sx={{mx:'auto',width:'90%',mt:3,display:{xs:'block',lg:'none'}}}>
                <FormGroup sx={{display:'grid',gridTemplateColumns:{xs:'repeat(2,1fr)',sm:'repeat(3,1fr)'},color:'#fff'}} >
                    <FormControlLabel control={<Checkbox style={{color:'#fff'}} checked={showActivities.includes('ADD NEW COIN')} onChange={handleCheck}  name="ADD NEW COIN"/>} label='Add New'/>
                    <FormControlLabel control={<Checkbox style={{color:'#fff'}} checked={showActivities.includes('ADD MORE COIN')} name="ADD MORE COIN" onChange={handleCheck}/>}   label='Add More'/>
                    <FormControlLabel control={<Checkbox style={{color:'#fff'}} checked={showActivities.includes('DELETE_COIN')} onChange={handleCheck}  name="DELETE_COIN"/>} label='Deleted'/>
                    <FormControlLabel control={<Checkbox style={{color:'#fff'}} checked={showActivities.includes('EDIT COIN')} onChange={handleCheck}  name="EDIT COIN"/>} label='Updated'/>
                    <FormControlLabel control={<Checkbox style={{color:'#fff'}} checked={showActivities.includes('LOGIN')} onChange={handleCheck}  name="LOGIN"/>} label='Log Ins'/>
                    <FormControlLabel control={<Checkbox style={{color:'#fff'}} checked={showActivities.includes('UPDATED_SELL')} onChange={handleCheck}  name="UPDATED_SELL" />} label='Xgain Updates'/>
                </FormGroup>
            </Box>

        <Table >
            <TableHead>
                <TableRow>
                    <TableCell sx={{color:"#fff"}} className="text-light" scope="col">#</TableCell>
                    <TableCell sx={{color:"#fff"}} className="text-light" scope="col">Date Time</TableCell>
                    {/* <th className="text-light" scope="col">Icon</th> */}
                    {/* <TableCell sx={{color:"#fff"}} className="text-light" scope="col">Symbol</TableCell> */}
                    <TableCell sx={{color:"#fff"}} className="text-light" scope="col">Activity Type</TableCell>
                    <TableCell sx={{color:"#fff"}} className="text-light" scope="col">Detail</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
              {getRows}
            </TableBody>
        </Table>
        </div>
    )
}

export default Activity;